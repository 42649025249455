import React from "react";
import styled from "styled-components";
import {
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok,
  FaFacebookF,
} from "react-icons/fa";

const Footer = () => {
  const handlePhoneCall = () => {
    const phoneNumber = "5468990701";
    window.open(`tel:${phoneNumber}`);
  };

  const handleEmail = () => {
    const emailAddress = "info@sohocraft.com";
    window.open(`mailto:${emailAddress}`);
  };

  const handleSocialMedia = (socialMedia) => {
    switch (socialMedia) {
      case "facebook":
        window.open("https://www.facebook.com/sohocraft");
        break;
      case "tiktok":
        window.open("https://tiktok.com/sohocraft");
        break;
      case "instagram":
        window.open("https://www.instagram.com/sohocraft");
        break;
      default:
        break;
    }
  };

  return (
    <FooterContainer>
      <Section>
        <SectionTitle>Bize Ulaşın</SectionTitle>
        <ContactItem onClick={handlePhoneCall}>
          <FaPhone color="#fffddd" size={20} />
          <ContactText>546-899-0701</ContactText>
        </ContactItem>
        <ContactItem onClick={handleEmail}>
          <FaEnvelope color="#fffddd" size={20} />
          <ContactText>info@sohocraft.com</ContactText>
        </ContactItem>
      </Section>
      <Section>
        <SectionTitle>Bizi Takip Edin</SectionTitle>
        <SocialMediaContainer>
          <SocialMediaButton onClick={() => handleSocialMedia("facebook")}>
            <FaFacebookF size={24} />
          </SocialMediaButton>
          <SocialMediaButton onClick={() => handleSocialMedia("tiktok")}>
            <FaTiktok size={24} />
          </SocialMediaButton>
          <SocialMediaButton onClick={() => handleSocialMedia("instagram")}>
            <FaInstagram size={24} />
          </SocialMediaButton>
        </SocialMediaContainer>
      </Section>
      <Section>
        <SectionTitle>Adresimiz</SectionTitle>
        <ContactText>
          SohoCraft Inc.
          <br />
          Kartal, İstanbul
          <br />
          Sultangazi, İstanbul
        </ContactText>
      </Section>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #1c1c1c;
  border-top: 1px solid #444;
  width: 100%;
  color: #fff;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Section = styled.div`
  margin: 20px 0;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0;
    flex: 1;
  }
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #ff5722;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  cursor: pointer;
`;

const ContactText = styled.span`
  font-size: 16px;
  margin-left: 10px;
  text-align: center;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialMediaButton = styled.button`
  margin: 0 10px;
  padding: 10px;
  border-radius: 50%;
  background-color: #333;
  border: none;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff5722;
  }
`;

export default Footer;
