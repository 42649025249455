import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Header = ({ currentPage }) => {
  function directToWhatsapp() {
    const phoneNumber = "5468990701";
    const message = "Merhaba, bir ürün için fiyat almak istiyorum";
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    const email = "teklif@sohocraft3d.com";
    const subject = "Fiyat Talebi";
    const emailUrl = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;

    // WhatsApp bağlantısını kontrol et ve yönlendir
    const whatsappWindow = window.open(url, "_blank");
    whatsappWindow.onload = function () {
      whatsappWindow.close();
      const emailWindow = window.open(emailUrl, "_blank");
      if (emailWindow) {
        emailWindow.focus();
      } else {
        alert(
          "Cihazda Email ve Whatsapp desteklenmiyor. Teklif almak için teklif@sohocraft3d.com adresine email atabilirsiniz."
        );
      }
    };
  }

  return (
    <StyledHeader>
      <LogoContainer>
        <LogoText>Soho Craft</LogoText>
      </LogoContainer>
      <MenuContainer>
        <StyledLink to="/" current={currentPage === "home" ? 1 : 0}>
          Ana Sayfa
        </StyledLink>
        <StyledLink to="/about" current={currentPage === "about" ? 1 : 0}>
          Hakkımızda
        </StyledLink>
        <StyledLink to="/gallery" current={currentPage === "gallery" ? 1 : 0}>
          Galeri
        </StyledLink>
        <Button onClick={directToWhatsapp}>
          <ButtonText>Teklif Al</ButtonText>
        </Button>
      </MenuContainer>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px;
  background-color: #20232a;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LogoContainer = styled.div`
  flex: 1;
`;

const LogoText = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #61dafb;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  margin-left: 20px;
  font-size: 18px;
  color: ${(props) => (props.current ? "#61dafb" : "white")};
  text-decoration: none;
  padding: 10px 15px;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    background-color: #282c34;
    border-radius: 5px;
  }
`;

const Button = styled.button`
  margin-left: 20px;
  background-color: #ff5722;
  padding: 10px 20px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #e64a19;
    transform: scale(1.05);
  }
`;

const ButtonText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
`;

export default Header;
