import React from "react";
import styled from "styled-components";
import AboutUs from "../components/About/About";
import Testimonials from "../components/About/Reviews";
import Footer from "../components/Footer";

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const AboutScreen = () => {
  return (
    <Container>
      <AboutUs />
      <Testimonials />
    </Container>
  );
};

export default AboutScreen;
