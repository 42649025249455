import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

function generateRandomUrls(count) {
  const urls = [];
  for (let i = 0; i < count; i++) {
    const randomId = Math.floor(Math.random() * 1000); // 0 ile 999 arasında rastgele bir sayı üretir
    const url = `https://picsum.photos/id/${randomId}/5000/3333`;
    urls.push(url);
  }
  return urls;
}

const images = generateRandomUrls(10);

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: currentIndex * sliderRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  return (
    <Section>
      <SectionTitle>PROJELERİMİZDEN KARELER</SectionTitle>
      <Slider>
        <ArrowLeft onClick={handlePrev}>
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </ArrowLeft>
        <ImageContainer ref={sliderRef}>
          {images.map((image, idx) => (
            <SectionImageContainer key={idx}>
              <SectionImage src={image} alt={`Project ${idx}`} />
            </SectionImageContainer>
          ))}
        </ImageContainer>
        <ArrowRight onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronRight} size="lg" />
        </ArrowRight>
      </Slider>
    </Section>
  );
};

const Section = styled.div`
  padding: 30px 0;
  background-color: #f0f4f8;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
`;

const Slider = styled.div`
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  scroll-behavior: smooth;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SectionImageContainer = styled.div`
  min-width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
`;

const SectionImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
`;

const ArrowLeft = styled(Arrow)`
  left: 10px;
`;

const ArrowRight = styled(Arrow)`
  right: 10px;
`;

export default Projects;
