import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const testimonials = [
  {
    name: "Ayşe Yılmaz",
    comment:
      "SohoCraft ile çalışmak harikaydı! Ürünler tam istediğim gibi oldu ve kalite mükemmeldi.",
    avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    rating: 5,
  },
  {
    name: "Ahmet Demir",
    comment:
      "Hızlı ve güvenilir hizmet. 3D baskı konusunda kesinlikle tavsiye ederim.",
    avatar: "https://randomuser.me/api/portraits/men/46.jpg",
    rating: 4,
  },
  {
    name: "Mehmet Kaya",
    comment:
      "Müşteri hizmetleri çok yardımcı oldu ve her adımda bilgilendirildim. Teşekkürler SohoCraft!",
    avatar: "https://randomuser.me/api/portraits/men/52.jpg",
    rating: 5,
  },
  {
    name: "Elif Şahin",
    comment:
      "Çok memnun kaldım, özellikle ürünlerin kalitesi ve detayları harika.",
    avatar: "https://randomuser.me/api/portraits/women/47.jpg",
    rating: 4,
  },
  {
    name: "Zeynep Öz",
    comment:
      "3D baskı hizmeti almak isteyen herkese tavsiye ederim. SohoCraft çok başarılı.",
    avatar: "https://randomuser.me/api/portraits/women/53.jpg",
    rating: 5,
  },
];

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  padding: 50px 20px;
  background-color: #f9f9f9;
  animation: ${fadeIn} 1s ease-in;
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 1.2px;
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const TestimonialCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px;
  min-width: 40%;
  max-width: 400px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  scroll-snap-align: center;
  text-align: center;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 15px;
`;

const Name = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const Comment = styled.p`
  font-size: 14px;
  color: #555;
  margin: 10px 0;
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const NormalDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #333;
  border-radius: 4px;
  margin: 0 4px;
  transition: width 0.3s ease-in-out;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Testimonials = () => {
  const [scrollX, setScrollX] = useState(0);
  const carouselRef = useRef(null);

  const handleScroll = () => {
    setScrollX(carouselRef.current.scrollLeft);
  };

  const renderStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={i <= rating ? faStar : faStarRegular}
          size="lg"
          color="#ffd700"
        />
      );
    }
    return <StarContainer>{stars}</StarContainer>;
  };

  return (
    <Container>
      <Title>Müşteri Yorumları</Title>
      <CarouselContainer ref={carouselRef} onScroll={handleScroll}>
        {testimonials.map((item, index) => (
          <TestimonialCard key={index}>
            <Avatar src={item.avatar} alt={item.name} />
            <Name>{item.name}</Name>
            <Comment>{item.comment}</Comment>
            {renderStarRating(item.rating)}
          </TestimonialCard>
        ))}
      </CarouselContainer>
      <IndicatorContainer>
        {testimonials.map((_, i) => (
          <NormalDot
            key={i}
            style={{
              width:
                scrollX >= i * (window.innerWidth * 0.3 + 20) &&
                scrollX < (i + 1) * (window.innerWidth * 0.3 + 20)
                  ? "16px"
                  : "8px",
            }}
          />
        ))}
      </IndicatorContainer>
    </Container>
  );
};

export default Testimonials;
