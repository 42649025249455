import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import Footer from "../components/Footer";
import Features from "../components/Home/Features";
import Projects from "../components/Home/ImageGallery";
import Splash from "../components/Home/Splash";
import FAQ from "../components/Home/Sss";
import Testimonials from "../components/About/Reviews";

const Home = () => {
  const fadeAnim = useRef(0);

  useEffect(() => {
    fadeAnim.current = 1;
  }, []);

  return (
    <Container>
      <Splash />
      <Features />
      <Projects />
      <FAQ />
      <Testimonials />
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  background-color: #f8f9fa;
  animation: ${fadeIn} 1.5s ease-in;
`;

export default Home;
