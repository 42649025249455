import React, { useState } from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const photos = [
  {
    uri: "https://via.placeholder.com/400x200.png?text=3D+Printed+Art+2",
    title: "3D Printed Art 2",
    description: "Description for 3D Printed Art 2",
  },
  {
    uri: "https://via.placeholder.com/400x400.png?text=3D+Printed+Art+1",
    title: "3D Printed Art 1",
    description: "Description for 3D Printed Art 1",
  },
  {
    uri: "https://via.placeholder.com/400x600.png?text=3D+Printed+Art+2",
    title: "3D Printed Art 2",
    description: "Description for 3D Printed Art 2",
  },
  {
    uri: "https://via.placeholder.com/400x450.png?text=3D+Printed+Art+1",
    title: "3D Printed Art 1",
    description: "Description for 3D Printed Art 1",
  },
  {
    uri: "https://via.placeholder.com/400x340.png?text=3D+Printed+Art+2",
    title: "3D Printed Art 2",
    description: "Description for 3D Printed Art 2",
  },
  {
    uri: "https://via.placeholder.com/400x640.png?text=3D+Printed+Art+1",
    title: "3D Printed Art 1",
    description: "Description for 3D Printed Art 1",
  },
  {
    uri: "https://via.placeholder.com/400x720.png?text=3D+Printed+Art+2",
    title: "3D Printed Art 2",
    description: "Description for 3D Printed Art 2",
  },
  {
    uri: "https://via.placeholder.com/400x320.png?text=3D+Printed+Art+2",
    title: "3D Printed Art 2",
    description: "Description for 3D Printed Art 2",
  },
];

const Gallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const openModal = (photo) => {
    setSelectedImage(photo);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <GalleryContainer>
      <GalleryTitle>Galeri</GalleryTitle>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photos.map((photo) => (
          <ImageContainer key={photo.uri} onClick={() => openModal(photo)}>
            <Image src={photo.uri} alt={photo.title} />
            <Overlay className="overlay">
              <OverlayTitle>{photo.title}</OverlayTitle>
              <OverlayDescription>{photo.description}</OverlayDescription>
            </Overlay>
          </ImageContainer>
        ))}
      </Masonry>
      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          style={customStyles}
          ariaHideApp={false}
        >
          <CloseButton onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} size="2x" color="#fff" />
          </CloseButton>
          <ModalContent>
            <FullScreenImage
              src={selectedImage.uri}
              alt={selectedImage.title}
            />
          </ModalContent>
        </Modal>
      )}
    </GalleryContainer>
  );
};

const GalleryContainer = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
`;

const GalleryTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 1.2;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  &:hover .overlay {
    opacity: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 15px;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  padding-top: 40px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const OverlayTitle = styled.h3`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const OverlayDescription = styled.p`
  color: #fff;
  font-size: 14px;
`;

const customStyles = {
  content: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    border: "none",
    borderRadius: "15px",
    padding: "0",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`;

const ModalContent = styled.div`
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 15px;
`;

export default Gallery;
