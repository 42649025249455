import React, { useState } from "react";
import styled from "styled-components";

const faqData = [
  {
    question: "SohoCraft 3D baskıyı nasıl kullanıyor?",
    answer:
      "SohoCraft, kişiselleştirilmiş ürünleri hayata geçirmek için gelişmiş 3D baskı teknolojisini kullanır ve dijital tasarımları hassasiyet ve ayrıntılarla fiziksel nesnelere dönüştürür.",
  },
  {
    question: "3D baskı ile hangi malzemeler kullanılıyor?",
    answer:
      "SohoCraft, PLA, ABS, PETG gibi çeşitli malzemelerle baskı yaparak farklı ihtiyaçlarınıza uygun çözümler sunar.",
  },
  {
    question: "Teslimat süresi ne kadar?",
    answer:
      "Baskının karmaşıklığına ve boyutuna bağlı olarak teslimat süreleri değişmekle birlikte, genellikle 3-7 iş günü arasında tamamlanmaktadır.",
  },
  {
    question: "Sipariş nasıl verilir?",
    answer:
      "Web sitemiz üzerinden veya doğrudan bizimle iletişime geçerek sipariş verebilirsiniz. Teknik destek ekibimiz size yardımcı olacaktır.",
  },
  {
    question: "3D baskının maliyeti nedir?",
    answer:
      "Maliyet, baskının boyutu, karmaşıklığı ve kullanılan malzemeye bağlı olarak değişir. Teklif almak için bize ulaşabilirsiniz.",
  },
  {
    question: "3D baskı dosyası nasıl hazırlanır?",
    answer:
      "Baskı dosyanızın STL, OBJ veya 3MF formatında olması gerekmektedir. Teknik ekibimiz, dosyanızın baskıya uygun olup olmadığını kontrol edebilir.",
  },
  {
    question: "Kalite kontrol süreci nasıldır?",
    answer:
      "Her baskı, teslimattan önce titiz bir kalite kontrol sürecinden geçer. Ürünlerimizin yüksek standartlarda olmasını sağlıyoruz.",
  },
  {
    question: "Farklı renk seçenekleri var mı?",
    answer:
      "Evet, çeşitli renk seçeneklerimiz mevcut. İstediğiniz renkleri bize bildirebilir veya mevcut seçeneklerimizden seçebilirsiniz.",
  },
  {
    question: "Baskı sonrası işlemler nelerdir?",
    answer:
      "Baskı sonrası işlemler arasında temizlik, zımparalama ve boyama yer alabilir. Bu işlemler, ürünün nihai görünümünü iyileştirmek için yapılır.",
  },
  {
    question: "3D baskı makineleri hangi markalar?",
    answer:
      "En iyi sonuçları elde etmek için endüstri lideri markaların 3D baskı makinelerini kullanıyoruz.",
  },
];

const FAQContainer = styled.div`
  max-width: 90%;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const FAQItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media (min-width: 900px) {
    width: calc(50% - 50px);
    margin-bottom: 20px;
  }
`;

const Question = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: #007bff;
  }
`;

const Answer = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: #666;
  max-height: ${(props) => (props.isVisible ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const ToggleIcon = styled.span`
  font-size: 24px;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isVisible ? "rotate(45deg)" : "rotate(0)")};
`;

const FAQ = () => {
  const [visibleFAQ, setVisibleFAQ] = useState(false);

  const toggleFAQ = (index) => {
    setVisibleFAQ(visibleFAQ === index ? null : index);
  };

  return (
    <FAQContainer>
      {faqData.map((faq, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleFAQ(index)}>
            {faq.question}
            <ToggleIcon isVisible={visibleFAQ === index}>+</ToggleIcon>
          </Question>
          <Answer isVisible={visibleFAQ === index}>{faq.answer}</Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;
