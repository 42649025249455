import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import Gallery from "./Gallery";
import About from "./About";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Nav = styled.nav`
  padding: 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
`;

const NavLink = styled(Link)`
  margin-right: 15px;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #007bff;
  }
`;

const Container = styled.div`
  padding: 20px 0;
`;

const Navigator = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default Navigator;
