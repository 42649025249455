import React from "react";
import styled from "styled-components";

const AboutUs = () => {
  return (
    <Container>
      <ContentContainer>
        <TextContainer>
          <Title>Misyonumuz</Title>
          <Text>
            SohoCraft olarak misyonumuz, en son 3D baskı teknolojilerini
            kullanarak, müşterilerimizin hayal gücünü gerçeğe dönüştürmelerine
            yardımcı olmaktır. Yaratıcılığı teşvik eder, yüksek kaliteli ve
            kişiselleştirilmiş çözümler sunarız.
          </Text>
          <Title>Vizyonumuz</Title>
          <Text>
            3D baskı teknolojisinde dünya lideri olmayı hedefliyoruz. Teknolojik
            yenilikleri takip ederek ve sürdürülebilirlik ilkesine bağlı
            kalarak, her projede mükemmellik ve yenilik sunmak için çalışıyoruz.
          </Text>
          <Title>Değerlerimiz</Title>
          <Text>
            <BoldText>Yenilik:</BoldText> Sürekli olarak yeni teknolojiler ve
            yöntemler araştırırız.
            <br />
            <BoldText>Kalite:</BoldText> Müşterilerimize en yüksek kalitede ürün
            ve hizmet sunarız.
            <br />
            <BoldText>Müşteri Odaklılık:</BoldText> Her müşterimizin ihtiyacını
            anlar ve onlara özel çözümler sunarız.
            <br />
            <BoldText>Sürdürülebilirlik:</BoldText> Çevreye duyarlı üretim
            süreçleri benimseriz.
          </Text>
        </TextContainer>
        <ImageContainer>
          <Image
            src="https://images.unsplash.com/photo-1557683316-973673baf926"
            alt="About Us"
          />
        </ImageContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f0f0f0;
  padding: 60px 20px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 24px;
  max-width: 1200px;
  margin: 0 auto;
`;

const TextContainer = styled.div`
  flex: 1;
  max-width: 80%;
  margin: 0 20px 30px 20px;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 5px;
  border-bottom: 3px solid #3498db;
  display: inline-block;
  padding-bottom: 5px;
`;

const Text = styled.p`
  font-size: 12px;
  color: #616d6d;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
`;

const BoldText = styled.span`
  font-weight: bold;
  color: #2c3e50;
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 600px;
  margin: 0 20px 30px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  @media (max-width: 826px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 400px;
  border-radius: 15px;
  margin-top: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export default AboutUs;
