import {
  faGem,
  faHandshake,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

const features = [
  {
    icon: faGem,
    title: "Yüksek Kaliteli Malzemeler",
    desc: "En yeni ve dayanıklı malzemeleri kullanarak üretim yapıyoruz.",
    hoverDesc:
      "Malzemelerimiz dayanıklılık testlerinden geçer ve uzun ömürlüdür.",
    color: "#FF6F61", // Mercan rengi
  },
  {
    icon: faRocket,
    title: "Hızlı ve Hassas Baskı",
    desc: "3D baskı işlemlerimiz hızlı ve yüksek hassasiyetle gerçekleştirilir.",
    hoverDesc: "Baskılarımız milimetrik hassasiyetle üretilir.",
    color: "#50e3c2",
  },
  {
    icon: faHandshake,
    title: "Profesyonel Danışmanlık",
    desc: "Sizlere her aşamada profesyonel danışmanlık hizmeti sunuyoruz.",
    hoverDesc: "Proje sürecinde size özel çözümler sunarız.",
    color: "#f5a623",
  },
];

const Features = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <FeaturesContainer>
      {features.map((feature, idx) => (
        <FeatureItem
          key={idx}
          onMouseEnter={() => setHoveredIndex(idx)}
          onMouseLeave={() => setHoveredIndex(null)}
          isHovered={hoveredIndex === idx}
        >
          <FontAwesomeIcon
            icon={feature.icon}
            style={{ color: feature.color, fontSize: 40 }}
          />
          <FeatureTitle>{feature.title}</FeatureTitle>
          <FeatureDescription>{feature.desc}</FeatureDescription>
          <HoverDescription>{feature.hoverDesc}</HoverDescription>
        </FeatureItem>
      ))}
    </FeaturesContainer>
  );
};

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  background-color: #f0f4f8;
`;

const FeatureItem = styled.div`
  width: calc(30% - 20px);
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
  transform: ${(props) => (props.isHovered ? "scale(1.1)" : "scale(1)")};
  position: relative;

  @media (max-width: 768px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    width: calc(950% - 20px);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const FeatureDescription = styled.p`
  font-size: 14px;
  color: #666;
`;

const HoverDescription = styled.div`
  font-size: 14px;
  color: #666;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export default Features;
