import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Splash = () => {
  const fadeAnim = useRef(0);
  const scaleAnim1 = useRef(1);
  const scaleAnim2 = useRef(1);

  useEffect(() => {
    fadeAnim.current = 1;
  }, []);

  const handlePressIn = (setScaleAnim) => {
    setScaleAnim(1.1);
  };

  const handlePressOut = (setScaleAnim) => {
    setScaleAnim(1);
  };

  const directToWhatsapp = () => {
    const phoneNumber = "5468990701";
    const message = "Merhaba, bir ürün için fiyat almak istiyorum";
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    const email = "teklif@sohocraft3d.com";
    const subject = "Fiyat Talebi";
    const emailUrl = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;

    window.open(url, "_blank").catch((err) => {
      console.error(
        "WhatsApp'a yönlendirme başarısız. Email ile devam ediliyor.",
        err
      );
      window.open(emailUrl, "_blank").catch((err) => {
        console.error("Email ile yönlendirme başarısız.", err);
        alert(
          "Cihazda Email ve Whatsapp desteklenmiyor. Teklif almak için teklif@sohocraft3d.com adresine email atabilirsiniz."
        );
      });
    });
  };

  return (
    <Container>
      <Header>
        <HeaderImage src={require("../../assets/images/splash.webp")} />
        <Overlay>
          <HeaderText>SOHO CRAFT</HeaderText>
          <HeaderSubText>Hayal gücünüzü gerçeğe dönüştürüyoruz</HeaderSubText>
          <ButtonContainer>
            <Button
              style={{ transform: `scale(${scaleAnim1.current})` }}
              onMouseDown={() => handlePressIn(scaleAnim1)}
              onMouseUp={() => handlePressOut(scaleAnim1)}
              onClick={directToWhatsapp}
            >
              <FontAwesomeIcon icon={faWhatsapp} />
              İletişime Geç
            </Button>
            <Button
              style={{ transform: `scale(${scaleAnim2.current})` }}
              onMouseDown={() => handlePressIn(scaleAnim2)}
              onMouseUp={() => handlePressOut(scaleAnim2)}
              onClick={directToWhatsapp}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              Teklif Al
            </Button>
          </ButtonContainer>
        </Overlay>
      </Header>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100vh;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation: ${fadeIn} 1.5s ease-in;
`;

const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const HeaderText = styled.h1`
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 5px #000;
`;

const HeaderSubText = styled.h2`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  text-align: center;
  text-shadow: 2px 2px 5px #000;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ff6f61;
  padding: 12px 30px;
  color: #fff;
  font-size: 18px;
  font-weight: semibold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;

export default Splash;
